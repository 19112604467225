<!-- =========================================================================================
    File Name: Position.vue
    Description: Page to change the placement xyz position in space
========================================================================================== -->

<template>
  <div class="vx-col">
    <vx-card no-shadow title="Visual effects">
      <div class="vx-row">
        <div class="vx-col">
          <label for="" class="vs-input--label">Should objects in this space cast shadows?</label>
          <vs-switch class="mb-base" color="success" v-model="enable_shadows">
            <span slot="on">On</span>
            <span slot="off">Off</span>
          </vs-switch>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col">
          <label for="" class="vs-input--label">Should light estimation be enabled?</label>
          <vs-switch class="mb-base" color="success" v-model="enable_light_estimation">
            <span slot="on">On</span>
            <span slot="off">Off</span>
          </vs-switch>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col">
          <label for="" class="vs-input--label">Set a fake floor instead of detecting surfaces at runtime. </label>
          <vs-switch class="mb-base" color="success" v-model="preposition_floor">
            <span slot="on">On</span>
            <span slot="off">Off</span>
          </vs-switch>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col">
          <label for="" class="vs-input--label"
            >Should things in the environement hide virtual content? (environment occlusion)</label
          >
          <vs-switch class="mb-base" color="success" v-model="enable_environment_occlusion">
            <span slot="on">On</span>
            <span slot="off">Off</span>
          </vs-switch>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col">
          <label for="" class="vs-input--label"
            >Should people in the environement hide virtual content? (human occlusion)
          </label>
          <vs-switch class="mb-base" color="success" v-model="enable_human_occlusion">
            <span slot="on">On</span>
            <span slot="off">Off</span>
          </vs-switch>
          <p>
            Only compatible on devices with the A12, A12x or the A13 bionic chips with iOS 13 and some Android capable
            phones. Activating this features might impact the general peformance of the experience.
          </p>
        </div>
      </div>
    </vx-card>

    <vx-card no-shadow title="Loader Tips" class="mb-6 mt-6">
      <!-- {{ space.settings.LoadingScreen.tips }} -->
      <!-- <label for="" class="vs-input--label">Decide  Date</label>
            <vs-switch color="success" v-model="on_loaded">
              <span slot="on">Wait</span>
              <span slot="off">Start Experience</span>
            </vs-switch> -->
      <!-- uniqueTips
            {{ uniqueTips }}
            this.space.settings.LoadingScreen.tips
            {{ this.space.settings.LoadingScreen.tips }} -->
      <!-- <vs-button @click="AddExistingTip(tip)" class="mr-3" type="line" v-for="(tip, i) in uniqueTips" :key="i"
              >+ {{ tip }}
            </vs-button> -->
      <!-- {{ uniqueTipsOptions }}
            <div class="vx-row">
              <div class="vx-col w-1/2">
                <span>Destination Space</span>
                <v-select
                  :clearable="false"
                  v-model="spacePidCopyDestination"
                  label="text"
                  class="w-full mb-2"
                  :options="uniqueTipsOptions"
                />
              </div>
            </div> -->
      <div class="vx-row mt-1 mb-2 w-full">
        <div class="vx-col w-3/12">
          <vs-button
            icon-pack="feather"
            icon="icon-plus"
            @click="AddTip(tipValue.text)"
            class="w-full"
            type="border"
            color="primary"
            >Add Tip</vs-button
          >
        </div>
        <div v-show="false">{{ pageRefreshHack }}</div>
      </div>
      <div v-for="(tip, i) in space.settings.LoadingScreen.tips" :key="i" class="vx-row mb-5 w-full">
        <div class="vx-col w-5/12">
          <vs-input class="w-full" label="Text" name="text" v-model="tip.text" />
        </div>
        <div class="vx-col w-2/12">
          <vs-input class="w-full" label="Duration" name="duration" v-model="tip.duration" />
        </div>
        <div class="vx-col mt-6 w-2/12">
          <vs-button
            color="danger"
            type="filled"
            @click="RemoveTip(tip)"
            icon-pack="feather"
            icon="icon-trash"
          ></vs-button>
        </div>
      </div>
      <label v-if="uniqueTipsOptions.length > 0" for="" class="vs-input--label"
        >Click on the tips below to add existing tips to this space :
      </label>
      <div class="vx-col flex">
        <div class="mt-2 mr-2" v-for="tip in uniqueTipsOptions" :key="tip.value" @click="AddTip(tip)">
          <vs-chip color="primary">{{ '+ ' + tip.text }} </vs-chip>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
// Import tools to crop the hologram
import '@/assets/js/utils/greenscreen-video-tools.js'
import * as HoverlayUtils from '@/assets/js/utils/hoverlay-utils.js'
import 'video.js/dist/video-js.css'
import vSelect from 'vue-select'

export default {
  inject: ['$validator'],
  components: {
    // 'v-select': vSelect,
  },
  props: {
    space: {},
  },
  data() {
    return {
      plan: HoverlayUtils.getLayerPlanName(),
      layer_name: JSON.parse(localStorage.getItem('layerInfo')).name,
      isCompassOriented: false,
      heading: 0,
      newTipText: 'Create from scratch',
      tipValue: { text: 'Create from scratch', value: 'Create from scratch' },
      uniqueTipsOptions: [],
      // space copy and remplace
      spacePidNameOptions: [],
      spacePidCopyDestination: null,
      enable_shadows: true,
      enable_light_estimation: true,
      preposition_floor: false,
      enable_environment_occlusion: false,
      enable_human_occlusion: false,
      is_access_level_unlisted: false,
      is_additive: false,
      on_loaded: false,
      present_from: null,
      present_to: null,
      config: {
        //wrap: true, // set wrap to true only when using 'input-group'
        // altFormat: 'M j, Y',
        altInput: true,
        // dateFormat: 'm-d-Y',
        enableTime: true,
      },
      modifiedPlacements: [],
      pageRefreshHack: 0,
    }
  },
  watch: {
    space: {
      handler(val) {
        this.$emit('updateSpace', this.space)
        this.u
      },
      deep: true,
    },
    isCompassOriented: function() {
      if (this.isCompassOriented == true) {
        console.log(this.space)
        if (this.space.settings == undefined) this.space.settings = {}
        this.space.settings['compass_oriented'] = true
      } else {
        this.space.settings['compass_oriented'] = false
      }
      this.$eventBus.$emit('spaceChanged', this.space)
    },
    heading: {
      deep: true,
      handler(newHeading) {
        if (!this.space.settings) this.space.settings = {}
        this.space.settings.heading = newHeading
      },
    },
    is_access_level_unlisted: {
      async handler(updatedValue) {
        if (updatedValue) this.space.access_level = 'unlisted'
        else {
          this.space.access_level = 'everyone'
          await this.$nextTick()
          this.initMap()
          this.showOtherSpaces()
          this.initMarker()
        }
      },
    },
    is_additive: {
      handler(updatedValue) {
        this.space.settings.is_additive = updatedValue
      },
    },
    enable_light_estimation: {
      handler(updatedValue) {
        if (updatedValue) this.space.settings.light_intensity = 1
        else this.space.settings.light_intensity = 0
      },
    },
    enable_shadows: {
      handler(updatedValue) {
        this.space.settings.enable_shadows = updatedValue
      },
    },
    preposition_floor: {
      handler(updatedValue) {
        this.space.settings.preposition_floor = updatedValue
      },
    },
    enable_environment_occlusion: {
      handler(updatedValue) {
        this.space.settings.enable_environment_occlusion = updatedValue
      },
    },
    enable_human_occlusion: {
      handler(updatedValue) {
        this.space.settings.enable_human_occlusion = updatedValue
      },
    },
    on_loaded: {
      handler(updatedValue) {
        if (updatedValue == 'wait') this.space.settings.LoadingScreen.on_loaded = 'wait'
        if (updatedValue == 'start_experience') this.space.settings.LoadingScreen.on_loaded = 'start_experience'
      },
    },
    range: {
      handler(updatedValue) {
        this.rangeIcon.setRadius(updatedValue)
        if (updatedValue == true) {
          this.$vs.loading.close()
        }
      },
    },
  },
  computed: {
    spaces() {
      if (this.$store.state.hoverlay) {
        console.log(this.$store.state.hoverlay.spaces)
        return this.$store.state.hoverlay.spaces
      } else return []
    },
    anchors() {
      if (this.$store.state.hoverlay) {
        return this.$store.state.hoverlay.anchors
      } else return []
    },
    tipCount() {
      if (this.space.settings && this.space.settings.LoadingScreen && this.space.settings.LoadingScreen.tips)
        return this.space.settings.LoadingScreen.tips.length
      else return 0
    },
    spacePidNameDictionnary() {
      var d = {}
      if (this.spaces)
        this.spaces.forEach(space => {
          d[space.pid] = space.name
        })
      return d
    },
  },
  mounted() {},
  created() {
    this.initData()
    // setup space settings
    if (!this.space.settings) this.space.settings = {}
    else {
      if (this.space.settings.compass_oriented == true) {
        this.isCompassOriented = true
      } else {
        this.isCompassOriented = false
      }
      if (this.space.settings.heading) this.heading = this.space.settings.heading
      if (this.space.settings.enable_environment_occlusion == true) this.enable_environment_occlusion = true
      if (this.space.settings.enable_human_occlusion == true) this.enable_human_occlusion = true
      if (this.space.settings.is_additive == true) this.is_additive = true
      if (this.space.settings.enable_shadows == false) this.enable_shadows = false
      if (this.space.settings.light_intensity == 0) this.enable_light_estimation = false
      if (this.space.settings.preposition_floor == false) this.preposition_floor = false
      else this.preposition_floor = true
    }

    if (this.space.access_level == 'everyone') this.is_access_level_unlisted = false
    else this.is_access_level_unlisted = true

    for (const [key, value] of Object.entries(this.spacePidNameDictionnary)) {
      if (key != this.space.pid) this.spacePidNameOptions.push({ text: value, value: key })
    }

    // LoadingScreen
    this.computeUniqueTips()

    if (this.space.settings.LoadingScreen == null)
      this.space.settings.LoadingScreen = { tips: [], on_loaded: 'start_experience' }
    if (this.space.settings.LoadingScreen.on_loaded) this.on_loaded = this.space.settings.LoadingScreen.on_loaded
    else this.on_loaded = 'start_experience'
    console.log(this.space.settings.LoadingScreen)
  },
  beforeDestroy() {},
  methods: {
    computeUniqueTips() {
      this.uniqueTipsOptions = []
      this.spaces.forEach(space => {
        if (this.space.pid != space.pid && space.settings && space.settings.LoadingScreen) {
          space.settings.LoadingScreen.tips.forEach(tip => {
            if (!this.uniqueTipsOptions.includes(tip.text))
              this.uniqueTipsOptions.push({ text: tip.text, value: tip.text })
          })
        }
      })
    },
    BackToMySpace() {
      this.$router.go(-1)
      // this.$router.push({
      //   path: `/space/${this.space.pid}`,
      // })
    },

    getAnchorName(anchor_pid) {
      return this.$store.state.hoverlay.anchors.filter(anchor => anchor.pid == anchor_pid)[0].name
    },

    editPlacementAnchor(placementPid) {
      this.$router.push({
        path: `/edit-placement-anchor/${placementPid}`,
      })
    },
    editHobject(hobject_pid) {
      this.$router.push({
        path: `/edit-hobject/${hobject_pid}`,
      })
    },
    onQrCodeCopy() {
      this.$vs.notify({
        title: 'Success',
        text: this.$t('QrCodeCopy'),
        color: 'success',
        iconPack: 'feather',
        position: 'bottom-left',
        icon: 'icon-check-circle',
      })
    },

    create() {
      this.$router.push({
        path: `/create/${this.latitude}/${this.longitude}`,
      })
    },
    updateSpace() {
      this.space.present_to = new Date(this.present_to).toISOString()
      this.space.present_from = new Date(this.present_from).toISOString()
      // Clear thumbnail field if it's not a File
      if (this.space.thumbnail && this.space.thumbnail instanceof Blob === false) delete this.space.thumbnail
      this.$store
        .dispatch('hoverlay/updateSpace', this.space)
        .then(() => {
          this.$vs.loading.close()
          this.$vs.notify({
            text: 'Space updated',
            color: 'success',
            icon: 'check_box',
          })
          console.log('SUCCESS')
        })
        .catch(error => {
          console.log(error.message)
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'Error',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    reset() {
      this.initData()
      this.initMap()
      this.showOtherSpaces()
      this.initMarker()
    },
    initData() {
      var space_pid = this.$route.params.space_pid
      // Create a clone of the space
      this.space = Object.assign(
        {},
        this.spaces.find(space => space.pid == space_pid)
      )
      this.present_from = new Date(this.space.present_from)
      this.present_to = new Date(this.space.present_to)
    },
    getHobject(hobject_pid) {
      return JSON.parse(
        JSON.stringify(this.$store.state.hoverlay.hobjects.filter(hobject => hobject.pid == hobject_pid)[0])
      )
    },
    AddExistingTip(text) {
      if (!this.space.settings.LoadingScreen) this.space.settings.LoadingScreen = {}
      if (!this.space.settings.LoadingScreen) this.space.settings.LoadingScreen.tips = []
      this.space.settings.LoadingScreen.tips.push({ text: text, duration: 4 })
      this.computeUniqueTips()
      this.pageRefreshHack += 1
    },
    AddTip(tipValue) {
      if (!this.space.settings.LoadingScreen) this.space.settings.LoadingScreen = {}
      if (!this.space.settings.LoadingScreen) this.space.settings.LoadingScreen.tips = []
      this.space.settings.LoadingScreen.tips.push({ text: tipValue.text, duration: 4 })
      this.computeUniqueTips()
      this.pageRefreshHack += 1
    },
    RemoveTip(tip) {
      const id = this.space.settings.LoadingScreen.tips.indexOf(tip) // 2
      this.space.settings.LoadingScreen.tips.splice(id, 1)
      this.pageRefreshHack += 1
    },
    ConfirmCopyAndReplace(spacePidCopyDestination) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Potentionaly destructive operation',
        text: 'You are about to copy a space into another one, the destination space will be erased',
        acceptText: 'Copy and Replace',
        accept: this.CopyAndReplace,
        parameters: [spacePidCopyDestination],
      })
    },
    async CopyAndReplace(spacePidCopyDestination) {
      this.$vs.loading()
      try {
        await this.$store.dispatch('hoverlay/copySpace', {
          source: this.space.pid,
          destination: spacePidCopyDestination[0],
        })
        await this.$store.dispatch('hoverlay/getSpaceContent', {
          layer_name: this.layer_name,
          pid: spacePidCopyDestination,
        })
        this.$vs.notify({
          text: 'Space copy in progress...',
          color: 'success',
          icon: 'check_box',
        })
      } catch (error) {
        this.$vs.notify({
          title: 'Error',
          text: error.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
        console.log(error)
      }
      this.$vs.loading.close()
    },
  },
}
</script>

<style lang="scss">
.card-no-padding > *:first-child {
  padding: 0rem;
}

.draggable {
  display: block;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  border-radius: 0.75em;
}

.marker {
  -webkit-filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.35));
  filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.35));
}
.leaflet-div-icon {
  background: none;
  border: none;
}
.space-marker {
  display: block;
  border-radius: 50%;
  cursor: pointer;
  padding: 0;
  z-index: 1000;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  /* -webkit-transition-duration: 0.4s;
        transition-duration: 0.4s; */
  /* overflow: hidden; */
}
.space-marker-not-expired {
  border: 3px solid rgb(255, 255, 255);
}

.space-maker-expired {
  border: 3px solid rgb(255, 0, 0);
}

.marker-description {
  visibility: hidden;
  font-size: 14px;
  font-weight: 900;
  line-height: 19px;
  box-sizing: border-box;
  padding: 4px 10px 5px 27px;
  border-radius: 10px;
  left: 45px;
  top: 10px;
  /* width: 130px; */
  /* margin-left: 10px; */
  /* margin-top: 10px; */
  /* max-width: 165.352px; */
  overflow: hidden;
  max-height: 46px;
  background-color: #ffffff;
  z-index: -9999999;
  position: absolute;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 15px;
}
</style>
