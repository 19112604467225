<!-- =========================================================================================
    File Name: Position.vue
    Description: Page to change the placement xyz position in space
========================================================================================== -->

<template>
  <div>
    <vx-card no-shadow title="Location settings">
      <div class="vx-row mb-6">
        <div class="vx-col w-1/2">
          <label class="vs-input--label">Restrict access to link or qr code only</label>
          <vs-switch color="success" v-model="is_access_level_unlisted">
            <span slot="on">On</span>
            <span slot="off">Off</span>
          </vs-switch>
        </div>
        <div v-show="is_access_level_unlisted" class="vx-col w-1/2">
          <vs-input :label="$t('EditPageLocationName')" class="w-full" v-model="space.name" />
        </div>
      </div>
      <div v-show="!is_access_level_unlisted">
        <div class="vx-row mb-6">
          <div class="vx-col w-1/3">
            <vs-input :label="$t('EditPageLocationName')" class="w-full" v-model="space.name" />
          </div>
          <div class="vx-col w-1/3">
            <vs-input
              label="Space label (People will see this in the mobile app)"
              class="w-full"
              v-model="space.description"
            />
          </div>
          <div class="vx-col w-1/3">
            <vs-input :label="$t('CreateAndPlaceWorkflowWhereRadius')" class="w-full" v-model.number="space.range" />
          </div>
        </div>

        <div class="vx-row">
          <div class="vx-col w-full">
            <vs-input
              v-on:keyup.enter="AdressSearch"
              icon="search"
              placeholder="Seach by name, address or latitude longitude"
              class="w-full"
              v-model="address"
            />
          </div>
        </div>

        <p>{{ $t('EditPageLocationmapDescription') }}</p>
        <div style="height: 530px">
          <div class="w-full bg-grid-color-secondary h-12">
            <div class="rounded-lg" style="height: 500px; width: 100%" id="map"></div>
          </div>
        </div>

        <!-- <div class="vx-row">
          <div class="vx-col">
            <label for="" class="vs-input--label"
              >Allow activation zone overlapping. If another the space is in range, also display its content.</label
            >
            <vs-switch class="mb-base" color="success" v-model="is_additive">
              <span slot="on">On</span>
              <span slot="off">Off</span>
            </vs-switch>
          </div>
        </div> -->
      </div>
    </vx-card>
    <br />
    <vx-card class="mb-5" title="Space Orientation">
      <div class="vx-col w-full">
        <vx-tooltip color="#7c53e6" text="Enable or disable compass orientation for the scene forward axis">
          <label for="" class="vs-input--label">Should this space align with a world compass heading?</label>
          <vs-switch color="success" v-model="isCompassOriented">
            <span slot="on">On</span>
            <span slot="off">Off</span>
          </vs-switch>
        </vx-tooltip>
        <div class="mt-5" v-show="isCompassOriented">
          <vx-tooltip
            class="w-1/3"
            color="#7c53e6"
            text="Choose compass orientation angle relative to the North Pole in degrees for the scene forward axis."
          >
            <label for="" class="vs-input--label">Scene Compass Heading</label>
          </vx-tooltip>

          <vs-slider ticks max="360" step="1" class="mb-5" text-fixed="°" :step-decimals="true" v-model="heading" />
          <div class="vx-row mb-6">
            <div class="vx-col w-3/12">North</div>
            <div class="vx-col w-3/12">East</div>
            <div class="vx-col w-3/12">South</div>
            <div class="vx-col w-3/12">West</div>
          </div>
          <!-- <vs-input-number min="-360" max="360" v-model="heading" /> -->
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
// Import tools to crop the hologram
import '@/assets/js/utils/greenscreen-video-tools.js'
import * as Utils from '@/assets/js/utils/utils.js'
import * as DestroyUtils from '@/assets/js/utils/destroy-utils.js'
import * as HoverlayUtils from '@/assets/js/utils/hoverlay-utils.js'
import 'video.js/dist/video-js.css'

import { uuid } from 'vue-uuid'
import vSelect from 'vue-select'

// Leaflet
import L from 'leaflet'

import 'leaflet.locatecontrol'
import 'leaflet.locatecontrol/dist/L.Control.Locate.min.css'

// Leaflet Pulse Plugin
import '@ansur/leaflet-pulse-icon/dist/L.Icon.Pulse.css'
import 'leaflet-pulse-icon'

export default {
  inject: ['$validator'],
  components: {},
  props: {
    space: {},
  },
  data() {
    return {
      plan: HoverlayUtils.getLayerPlanName(),
      layer_name: JSON.parse(localStorage.getItem('layerInfo')).name,
      isCompassOriented: false,
      heading: 0,
      newTipText: 'Create from scratch',
      tipValue: { text: 'Create from scratch', value: 'Create from scratch' },
      uniqueTipsOptions: [],
      // space copy and remplace
      spacePidNameOptions: [],
      spacePidCopyDestination: null,
      enable_shadows: true,
      enable_light_estimation: true,
      preposition_floor: false,
      enable_environment_occlusion: false,
      enable_human_occlusion: false,
      is_access_level_unlisted: false,
      is_additive: false,
      on_loaded: false,
      present_from: null,
      present_to: null,
      modifiedPlacements: [],
      address: '',
      // Map
      // pulsingIconDisable: null,
      // pulsingIconEnable: null,
      markerIcon: null,
      rangeIcon: null,
      RoadmapLayer: L.tileLayer('https://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}', {
        attribution: '&copy;',
        maxNativeZoom: 19,
        maxZoom: 20,
      }),
      TerrainLayer: L.tileLayer('https://mt0.google.com/vt/lyrs=p&hl=en&x={x}&y={y}&z={z}', {
        attribution: '&copy;',
        maxNativeZoom: 19,
        maxZoom: 20,
      }),
      AlteredRoadmapLayer: L.tileLayer('https://mt0.google.com/vt/lyrs=r&hl=en&x={x}&y={y}&z={z}', {
        attribution: '&copy;',
        maxNativeZoom: 19,
        maxZoom: 20,
      }),
      SatelliteOnlyLayer: L.tileLayer('https://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}', {
        attribution: '&copy;',
        maxNativeZoom: 19,
        maxZoom: 20,
      }),
      TerrainOnlyLayer: L.tileLayer('https://mt0.google.com/vt/lyrs=t&hl=en&x={x}&y={y}&z={z}', {
        attribution: '&copy;',
        maxNativeZoom: 19,
        maxZoom: 20,
      }),
      HybridLayer: L.tileLayer('https://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}', {
        attribution: '&copy;',
        maxNativeZoom: 19,
        maxZoom: 20,
      }),
      popup: L.popup(),
      pageRefreshHack: 0,
    }
  },
  watch: {
    space: {
      handler(val) {
        this.$emit('updateSpace', this.space)
        this.rangeIcon.setRadius(val.range)
        this.u
      },
      deep: true,
    },
    isCompassOriented: function() {
      if (this.isCompassOriented == true) {
        console.log(this.space)
        if (this.space.settings == undefined) this.space.settings = {}
        this.space.settings['compass_oriented'] = true
      } else {
        this.space.settings['compass_oriented'] = false
      }
      this.$eventBus.$emit('spaceChanged', this.space)
    },
    heading: {
      deep: true,
      handler(newHeading) {
        if (!this.space.settings) this.space.settings = {}
        this.space.settings.heading = newHeading
      },
    },
    is_access_level_unlisted: {
      async handler(updatedValue) {
        if (updatedValue) this.space.access_level = 'unlisted'
        else {
          this.space.access_level = 'everyone'
          await this.$nextTick()
          this.initMap()
          this.showOtherSpaces()
          this.initMarker()
        }
      },
    },
    is_additive: {
      handler(updatedValue) {
        this.space.settings.is_additive = updatedValue
      },
    },
    enable_light_estimation: {
      handler(updatedValue) {
        if (updatedValue) this.space.settings.light_intensity = 1
        else this.space.settings.light_intensity = 0
      },
    },
    enable_shadows: {
      handler(updatedValue) {
        this.space.settings.enable_shadows = updatedValue
      },
    },
    preposition_floor: {
      handler(updatedValue) {
        this.space.settings.preposition_floor = updatedValue
      },
    },
    enable_environment_occlusion: {
      handler(updatedValue) {
        this.space.settings.enable_environment_occlusion = updatedValue
      },
    },
    enable_human_occlusion: {
      handler(updatedValue) {
        this.space.settings.enable_human_occlusion = updatedValue
      },
    },
    on_loaded: {
      handler(updatedValue) {
        if (updatedValue == 'wait') this.space.settings.LoadingScreen.on_loaded = 'wait'
        if (updatedValue == 'start_experience') this.space.settings.LoadingScreen.on_loaded = 'start_experience'
      },
    },
    range: {
      handler(updatedValue) {
        this.rangeIcon.setRadius(updatedValue)
        if (updatedValue == true) {
          this.$vs.loading.close()
        }
      },
    },
  },
  computed: {
    spaces() {
      if (this.$store.state.hoverlay) {
        console.log(this.$store.state.hoverlay.spaces)
        return this.$store.state.hoverlay.spaces
      } else return []
    },
    anchors() {
      if (this.$store.state.hoverlay) {
        return this.$store.state.hoverlay.anchors
      } else return []
    },
    tipCount() {
      if (this.space.settings && this.space.settings.LoadingScreen && this.space.settings.LoadingScreen.tips)
        return this.space.settings.LoadingScreen.tips.length
      else return 0
    },
    spacePidNameDictionnary() {
      var d = {}
      if (this.spaces)
        this.spaces.forEach(space => {
          d[space.pid] = space.name
        })
      return d
    },
  },
  mounted() {
    console.log('MOUNTED D D D D')
    this.initMap()
    this.showOtherSpaces()
    this.initMarker()
    this.computeUniqueTips()
  },
  created() {
    this.initData()
    // setup space settings
    if (!this.space.settings) this.space.settings = {}
    else {
      if (this.space.settings.compass_oriented == true) {
        this.isCompassOriented = true
      } else {
        this.isCompassOriented = false
      }
      if (this.space.settings.heading) this.heading = this.space.settings.heading
      if (this.space.settings.enable_environment_occlusion == true) this.enable_environment_occlusion = true
      if (this.space.settings.enable_human_occlusion == true) this.enable_human_occlusion = true
      if (this.space.settings.is_additive == true) this.is_additive = true
      if (this.space.settings.enable_shadows == false) this.enable_shadows = false
      if (this.space.settings.light_intensity == 0) this.enable_light_estimation = false
      if (this.space.settings.preposition_floor == false) this.preposition_floor = false
      else this.preposition_floor = true
    }

    if (this.space.access_level == 'everyone') this.is_access_level_unlisted = false
    else this.is_access_level_unlisted = true

    for (const [key, value] of Object.entries(this.spacePidNameDictionnary)) {
      if (key != this.space.pid) this.spacePidNameOptions.push({ text: value, value: key })
    }

    // LoadingScreen
    console.log('this.space.settings.LoadingScreen')

    console.log(this.space.settings.LoadingScreen)
    if (this.space.settings.LoadingScreen == null)
      this.space.settings.LoadingScreen = { tips: [], on_loaded: 'start_experience' }
    if (this.space.settings.LoadingScreen.on_loaded) this.on_loaded = this.space.settings.LoadingScreen.on_loaded
    else this.on_loaded = 'start_experience'
    console.log(this.space.settings.LoadingScreen)
  },
  beforeDestroy() {
    DestroyUtils.destroyLeaflet(
      {
        leafletMap: this.leafletMap,
        layers: [
          this.RoadmapLayer,
          this.TerrainLayer,
          this.AlteredRoadmapLayer,
          this.SatelliteOnlyLayer,
          this.TerrainOnlyLayer,
          this.HybridLayer,
        ],
      },
      this
    )
  },
  methods: {
    computeUniqueTips() {
      this.uniqueTipsOptions = []
      this.uniqueTipsOptions.push({ text: this.newTipText, value: this.newTipText })
      // console.log('computeUniqueTips')
      this.spaces.forEach(space => {
        if (this.space.pid != space.pid && space.settings && space.settings.LoadingScreen) {
          // console.log(space.settings.LoadingScreen.tips.length)
          //     console.log('x x x x x x x x ')
          //     console.log(this.space.settings)
          space.settings.LoadingScreen.tips.forEach(tip => {
            // console.log('tip')
            // console.log(tip.text)
            if (!this.uniqueTipsOptions.includes(tip.text))
              this.uniqueTipsOptions.push({ text: tip.text, value: tip.text })
          })
        }
      })
    },
    async AdressSearch() {
      // Check if the address is a lat long or an street address
      var latLongRegex = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/
      var isAddressLatLong = latLongRegex.test(this.address)
      if (isAddressLatLong) {
        var ExtractNumberRegex = /[+-]?\d+(\.\d+)?/g
        var coordinates = this.address.match(ExtractNumberRegex).map(function(v) {
          return parseFloat(v)
        })

        this.marker = [coordinates[0], coordinates[1]]
        this.leafletMap.panTo([coordinates[0], coordinates[1]])

        this.placement.latitude = coordinates[0]
        this.placement.longitude = coordinates[1]

        return true
      }
      this.$vs.loading()
      var response
      try {
        response = await this.$store.dispatch('hoverlay/getLatLongFromAddress', this.address)
      } catch (err) {
        this.$vs.notify({
          title: 'Error',
          text: 'Could not find the requested location',
          color: 'danger',
        })
        console.error(err)
      } finally {
        this.$vs.loading.close()
      }
      if (response) {
        var firstResult = response[0]

        this.rangeIcon.setLatLng([firstResult.lat, firstResult.lon])

        this.modifiedPlacements.forEach(modifiedPlacement => {
          modifiedPlacement.latitude = parseFloat(firstResult.lat)
          modifiedPlacement.longitude = parseFloat(firstResult.lon)
        })
        this.leafletMap.panTo([parseFloat(firstResult.lat), parseFloat(firstResult.lon)])
      }
    },
    BackToMySpace() {
      this.$router.go(-1)
      // this.$router.push({
      //   path: `/space/${this.space.pid}`,
      // })
    },

    getAnchorName(anchor_pid) {
      return this.$store.state.hoverlay.anchors.filter(anchor => anchor.pid == anchor_pid)[0].name
    },
    confirmDeletePlacement(placementPid) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: this.$t('MySpaceDeletionTitle'),
        text: this.$t('MySpaceDeletionDescription'),
        acceptText: this.$t('Delete'),
        accept: this.deletePlacement,
        parameters: [placementPid],
      })
    },
    deletePlacement(placementPid) {
      console.log('deletePlacement' + placementPid)
      this.$store
        .dispatch('hoverlay/deletePlacement', [placementPid])
        .then(() => {
          this.$vs.loading.close()
          this.$vs.notify({
            color: 'success',
            title: 'Success',
            text: 'the space has been deleted! ',
          })

          // Remove the placement from the list
          this.modifiedPlacements = this.modifiedPlacements.filter(placement => placement.pid != placementPid)
        })
        .catch(err => {
          this.$vs.loading.close()
          console.error(err)
        })
    },
    editPlacementAnchor(placementPid) {
      this.$router.push({
        path: `/edit-placement-anchor/${placementPid}`,
      })
    },
    editHobject(hobject_pid) {
      this.$router.push({
        path: `/edit-hobject/${hobject_pid}`,
      })
    },
    onQrCodeCopy() {
      this.$vs.notify({
        title: 'Success',
        text: this.$t('QrCodeCopy'),
        color: 'success',
        iconPack: 'feather',
        position: 'bottom-left',
        icon: 'icon-check-circle',
      })
    },

    create() {
      this.$router.push({
        path: `/create/${this.latitude}/${this.longitude}`,
      })
    },
    updateSpace() {
      this.space.present_to = new Date(this.present_to).toISOString()
      this.space.present_from = new Date(this.present_from).toISOString()
      // Clear thumbnail field if it's not a File
      if (this.space.thumbnail && this.space.thumbnail instanceof Blob === false) delete this.space.thumbnail
      this.$store
        .dispatch('hoverlay/updateSpace', this.space)
        .then(() => {
          this.$vs.loading.close()
          this.$vs.notify({
            text: 'Space updated',
            color: 'success',
            icon: 'check_box',
          })
          console.log('SUCCESS')
        })
        .catch(error => {
          console.log(error.message)
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'Error',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    reset() {
      this.initData()
      this.initMap()
      this.showOtherSpaces()
      this.initMarker()
    },
    initData() {
      var space_pid = this.$route.params.space_pid
      // Create a clone of the space
      this.space = Object.assign(
        {},
        this.spaces.find(space => space.pid == space_pid)
      )
      this.present_from = new Date(this.space.present_from)
      this.present_to = new Date(this.space.present_to)
    },
    getHobject(hobject_pid) {
      return JSON.parse(
        JSON.stringify(this.$store.state.hoverlay.hobjects.filter(hobject => hobject.pid == hobject_pid)[0])
      )
    },
    resetMarker() {
      this.leafletMap.removeLayer(this.markerIcon)
      this.leafletMap.removeLayer(this.rangeIcon)
    },
    showOtherSpaces() {
      this.spaces.forEach(space => {
        if (this.space.pid != space.pid) {
          this.grayCircle = L.circle([space.latitude, space.longitude], {
            color: '#000000',
            // fillColor: '#000000',
            opacity: 0.2,
            fillOpacity: 0,
            stroke: true,
            radius: space.range,
          })
          // this.grayCircle.setForceZIndex(-1000)

          this.grayCircle.addTo(this.leafletMap)
        }
      })
    },
    initMarker() {
      var containerElement = document.createElement('div')
      containerElement.style.width = `60px`
      containerElement.style.height = `60px`
      containerElement.style.backgroundSize = '100%'
      containerElement.style.position = 'absolute'

      var thumbnailElement = document.createElement('div')
      containerElement.appendChild(thumbnailElement)

      thumbnailElement.className = 'space-marker'
      thumbnailElement.id = `space-${this.space.pid}`
      thumbnailElement.setAttribute('this.space_pid', this.space.pid)

      console.log(new Date() < new Date(this.space.present_to))
      if (new Date() < new Date(this.space.present_to)) thumbnailElement.classList.add('space-marker-not-expired')
      else thumbnailElement.classList.add('space-maker-expired')

      if (this.space.thumbnail_small) thumbnailElement.style.backgroundImage = `url(${this.space.thumbnail_small})`
      else thumbnailElement.style.backgroundImage = `url(${require('@/assets/images/hoverlay/Icon-512.png')})`
      thumbnailElement.style.width = `60px`

      thumbnailElement.style.height = `60px`
      thumbnailElement.style.backgroundSize = '100%'
      thumbnailElement.style.position = 'absolute'

      if (this.space.description && this.space.description != 'null') {
        var descriptionElement = document.createElement('div')
        descriptionElement.className = 'marker-description'
        descriptionElement.id = `description-${this.space.pid}`
        var width = 120
        if (this.space.description.length < 12) {
          width = this.space.description.length * 18
          descriptionElement.style.top = '20px'
        }
        descriptionElement.style.width = `${width}px`
        descriptionElement.innerHTML = this.space.description
        containerElement.appendChild(descriptionElement)
      }
      this.rangeIcon = L.circle([this.space.latitude, this.space.longitude], {
        color: '#7367F0',
        fillColor: '#7367F0',
        fillOpacity: 0.2,
        radius: this.space.range,
        bubblingMouseEvents: true,
      })
      var icon = L.divIcon({
        html: containerElement.outerHTML,
        iconSize: [60, 60],
        iconAnchor: [30, 30],
      })
      this.markerIcon = L.marker([this.space.latitude, this.space.longitude], {
        icon: icon,
        draggable: true,
        bubblingMouseEvents: true,
      }).on('click', this.OnMarkerClick)

      this.leafletMap.fitBounds(L.latLng(this.space.latitude, this.space.longitude).toBounds(this.space.range * 10))
      console.log(this.markerIcon)
      this.moveableMarker(this.leafletMap, this.markerIcon)
      this.moveableMarker(this.leafletMap, this.rangeIcon)
      this.markerIcon.addTo(this.leafletMap)
      this.rangeIcon.addTo(this.leafletMap)
    },
    initMap() {
      if (this.leafletMap) {
        this.leafletMap.off()
        this.leafletMap.remove()
      }
      // Map creation
      this.leafletMap = L.map('map', {
        center: [0, 0],
        layers: [
          this.TerrainLayer,
          this.SatelliteOnlyLayer,
          this.HybridLayer,
          this.AlteredRoadmapLayer,
          this.RoadmapLayer,
        ],
        zoom: 2,
      })

      // Locate
      L.control.locate().addTo(this.leafletMap)

      var baseLayers = {
        Terrain: this.TerrainLayer,
        Satellite: this.SatelliteOnlyLayer,
        Hybrid: this.HybridLayer,
        'Altered Road Map': this.AlteredRoadmapLayer,
        'Road Map': this.RoadmapLayer,
      }
      L.control.layers(baseLayers).addTo(this.leafletMap)
      this.layer = new L.FeatureGroup().addTo(this.leafletMap)
      this.leafletMap.on('click', this.onMapClick)
    },
    onMapClick(e) {
      var self = this
      this.popup
        .setLatLng(e.latlng)
        .setContent(this.createHtmlPopup(e.latlng, e.latlng))
        .openOn(this.leafletMap)

      var button = document.querySelector('.edit-placement-button')
      button.onclick = null
      button.onclick = function(event) {
        self.markerIcon.setLatLng(self.popup._latlng)
        self.rangeIcon.setLatLng(self.popup._latlng)
        self.space.latitude = self.popup._latlng.lat
        self.space.longitude = self.popup._latlng.lng
        self.leafletMap.closePopup()
      }
    },
    moveableMarker(map, marker) {
      const context = this
      function trackCursor(evt) {
        marker.setLatLng(evt.latlng)
        console.log(this.rangeIcon)
        context.rangeIcon.setLatLng(evt.latlng)
        context.markerIcon.setLatLng(evt.latlng)
        context.space.latitude = evt.latlng.lat
        context.space.longitude = evt.latlng.lng
      }

      marker.on('mousedown', () => {
        context.leafletMap.off('click', this.onMapClick)
        map.dragging.disable()
        map.on('mousemove', trackCursor)
      })

      marker.on('mouseup', () => {
        map.dragging.enable()
        map.off('mousemove', trackCursor)
        setTimeout(function() {
          context.leafletMap.on('click', context.onMapClick)
        }, 10)
      })

      return marker
    },
    createHtmlPopup(latitude, longitude) {
      return `<center><h5>Do you want to relocated this space here ?</h5> <br>
          <button data-lat="${latitude}" data-lon="${longitude}" type="button" name="button" class="edit-placement-button vs-component vs-button w-full  vs-button-primary vs-button-border">
          <span data-lat="${latitude}" data-lon="${longitude}" class="vs-button-text vs-button--text">Confirm</span>
          </button><br>`
    },
    AddExistingTip(text) {
      if (!this.space.settings.LoadingScreen) this.space.settings.LoadingScreen = {}
      if (!this.space.settings.LoadingScreen) this.space.settings.LoadingScreen.tips = []
      this.space.settings.LoadingScreen.tips.push({ text: text, duration: 4 })
      this.computeUniqueTips()
      this.pageRefreshHack += 1
    },
    AddTip(tipValue) {
      if (!this.space.settings.LoadingScreen) this.space.settings.LoadingScreen = {}
      if (!this.space.settings.LoadingScreen) this.space.settings.LoadingScreen.tips = []
      if (tipValue.text == this.newTipText) {
        this.space.settings.LoadingScreen.tips.push({ text: 'Customize me', duration: 4 })
      } else this.space.settings.LoadingScreen.tips.push({ text: tipValue.text, duration: 4 })

      this.computeUniqueTips()
      this.pageRefreshHack += 1
    },
    RemoveTip(tip) {
      const id = this.space.settings.LoadingScreen.tips.indexOf(tip) // 2
      this.space.settings.LoadingScreen.tips.splice(id, 1)
      this.pageRefreshHack += 1
    },
    ConfirmCopyAndReplace(spacePidCopyDestination) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Potentionaly destructive operation',
        text: 'You are about to copy a space into another one, the destination space will be erased',
        acceptText: 'Copy and Replace',
        accept: this.CopyAndReplace,
        parameters: [spacePidCopyDestination],
      })
    },
    async CopyAndReplace(spacePidCopyDestination) {
      this.$vs.loading()
      try {
        await this.$store.dispatch('hoverlay/copySpace', {
          source: this.space.pid,
          destination: spacePidCopyDestination[0],
        })
        await this.$store.dispatch('hoverlay/getSpaceContent', {
          layer_name: this.layer_name,
          pid: spacePidCopyDestination,
        })
        this.$vs.notify({
          text: 'Space copy in progress...',
          color: 'success',
          icon: 'check_box',
        })
      } catch (error) {
        this.$vs.notify({
          title: 'Error',
          text: error.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
        console.log(error)
      }
      this.$vs.loading.close()
    },
  },
}
</script>

<style lang="scss">
.card-no-padding > *:first-child {
  padding: 0rem;
}

.draggable {
  display: block;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  border-radius: 0.75em;
}

.marker {
  -webkit-filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.35));
  filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.35));
}
.leaflet-div-icon {
  background: none;
  border: none;
}
.space-marker {
  display: block;
  border-radius: 50%;
  cursor: pointer;
  padding: 0;
  z-index: 1000;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  /* -webkit-transition-duration: 0.4s;
        transition-duration: 0.4s; */
  /* overflow: hidden; */
}
.space-marker-not-expired {
  border: 3px solid rgb(255, 255, 255);
}

.space-maker-expired {
  border: 3px solid rgb(255, 0, 0);
}

.marker-description {
  visibility: hidden;
  font-size: 14px;
  font-weight: 900;
  line-height: 19px;
  box-sizing: border-box;
  padding: 4px 10px 5px 27px;
  border-radius: 10px;
  left: 45px;
  top: 10px;
  /* width: 130px; */
  /* margin-left: 10px; */
  /* margin-top: 10px; */
  /* max-width: 165.352px; */
  overflow: hidden;
  max-height: 46px;
  background-color: #ffffff;
  z-index: -9999999;
  position: absolute;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 15px;
}
</style>
