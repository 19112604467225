<!-- =========================================================================================
    File Name: Position.vue
    Description: Page to change the placement xyz position in space
========================================================================================== -->

<template>
  <vx-card no-shadow v-show="!is_access_level_unlisted" title="Duration settings">
    <div class="vx-row mb-4">
      <div class="vx-col w-full">
        <label for="" class="vs-input--label">From Date</label>
        <flat-pickr :config="config" class="w-full" v-model="present_from" />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <label for="" class="vs-input--label">To</label>
        <flat-pickr :config="config" class="w-full" v-model="present_to" />
      </div>
    </div>

    <vs-alert color="primary" icon="new_releases" active="true" class="mb-1 mt-6">
      <span>
        All times local to your location ({{ Intl.DateTimeFormat().resolvedOptions().timeZone }}).<br />
        Be mindful that your space might be in a different time zone.</span
      >
    </vs-alert>
  </vx-card>
</template>

<script>
// Import tools to crop the hologram
import '@/assets/js/utils/greenscreen-video-tools.js'
import * as Utils from '@/assets/js/utils/utils.js'
import * as DestroyUtils from '@/assets/js/utils/destroy-utils.js'
import * as HoverlayUtils from '@/assets/js/utils/hoverlay-utils.js'
import 'video.js/dist/video-js.css'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import { uuid } from 'vue-uuid'
import vSelect from 'vue-select'

// Leaflet
import L from 'leaflet'

import 'leaflet.locatecontrol'
import 'leaflet.locatecontrol/dist/L.Control.Locate.min.css'

// Leaflet Pulse Plugin
import '@ansur/leaflet-pulse-icon/dist/L.Icon.Pulse.css'
import 'leaflet-pulse-icon'

export default {
  inject: ['$validator'],
  components: {
    flatPickr,
  },
  props: {
    space: {},
  },
  data() {
    return {
      plan: HoverlayUtils.getLayerPlanName(),
      layer_name: JSON.parse(localStorage.getItem('layerInfo')).name,
      newTipText: 'Create from scratch',
      config: {
        //wrap: true, // set wrap to true only when using 'input-group'
        // altFormat: 'M j, Y',
        altInput: true,
        // dateFormat: 'm-d-Y',
        enableTime: true,
      },
      present_from: null,
      present_to: null,
    }
  },
  watch: {
    present_from: {
      handler(val) {
        this.space.present_from = new Date(this.present_from).toISOString()
        this.$emit('updateSpace', this.space)
      },
      deep: true,
    },
    present_to: {
      handler(val) {
        this.space.present_to = new Date(this.present_to).toISOString()
        this.$emit('updateSpace', this.space)
      },
      deep: true,
    },
    space: {
      handler(val) {
        this.$emit('updateSpace', this.space)
      },
      deep: true,
    },
    isCompassOriented: function() {
      if (this.isCompassOriented == true) {
        console.log(this.space)
        if (this.space.settings == undefined) this.space.settings = {}
        this.space.settings['compass_oriented'] = true
      } else {
        this.space.settings['compass_oriented'] = false
      }
      this.$eventBus.$emit('spaceChanged', this.space)
    },
    heading: {
      deep: true,
      handler(newHeading) {
        if (!this.space.settings) this.space.settings = {}
        this.space.settings.heading = newHeading
      },
    },
    is_access_level_unlisted: {
      async handler(updatedValue) {
        if (updatedValue) this.space.access_level = 'unlisted'
        else {
          this.space.access_level = 'everyone'
          await this.$nextTick()
          this.initMap()
          this.showOtherSpaces()
          this.initMarker()
        }
      },
    },
    is_additive: {
      handler(updatedValue) {
        this.space.settings.is_additive = updatedValue
      },
    },
    enable_light_estimation: {
      handler(updatedValue) {
        if (updatedValue) this.space.settings.light_intensity = 1
        else this.space.settings.light_intensity = 0
      },
    },
    enable_shadows: {
      handler(updatedValue) {
        this.space.settings.enable_shadows = updatedValue
      },
    },
    preposition_floor: {
      handler(updatedValue) {
        this.space.settings.preposition_floor = updatedValue
      },
    },
    enable_environment_occlusion: {
      handler(updatedValue) {
        this.space.settings.enable_environment_occlusion = updatedValue
      },
    },
    enable_human_occlusion: {
      handler(updatedValue) {
        this.space.settings.enable_human_occlusion = updatedValue
      },
    },
    on_loaded: {
      handler(updatedValue) {
        if (updatedValue == 'wait') this.space.settings.LoadingScreen.on_loaded = 'wait'
        if (updatedValue == 'start_experience') this.space.settings.LoadingScreen.on_loaded = 'start_experience'
      },
    },
    range: {
      handler(updatedValue) {
        this.rangeIcon.setRadius(updatedValue)
        if (updatedValue == true) {
          this.$vs.loading.close()
        }
      },
    },
  },
  computed: {
    spaces() {
      if (this.$store.state.hoverlay) {
        console.log(this.$store.state.hoverlay.spaces)
        return this.$store.state.hoverlay.spaces
      } else return []
    },
    anchors() {
      if (this.$store.state.hoverlay) {
        return this.$store.state.hoverlay.anchors
      } else return []
    },
    tipCount() {
      if (this.space.settings && this.space.settings.LoadingScreen && this.space.settings.LoadingScreen.tips)
        return this.space.settings.LoadingScreen.tips.length
      else return 0
    },
    spacePidNameDictionnary() {
      var d = {}
      if (this.spaces)
        this.spaces.forEach(space => {
          d[space.pid] = space.name
        })
      return d
    },
  },
  mounted() {},
  created() {
    this.initData()
    // setup space settings
    if (!this.space.settings) this.space.settings = {}
    else {
      if (this.space.settings.compass_oriented == true) {
        this.isCompassOriented = true
      } else {
        this.isCompassOriented = false
      }
      if (this.space.settings.heading) this.heading = this.space.settings.heading
      if (this.space.settings.enable_environment_occlusion == true) this.enable_environment_occlusion = true
      if (this.space.settings.enable_human_occlusion == true) this.enable_human_occlusion = true
      if (this.space.settings.is_additive == true) this.is_additive = true
      if (this.space.settings.enable_shadows == false) this.enable_shadows = false
      if (this.space.settings.light_intensity == 0) this.enable_light_estimation = false
      if (this.space.settings.preposition_floor == false) this.preposition_floor = false
      else this.preposition_floor = true
    }

    if (this.space.access_level == 'everyone') this.is_access_level_unlisted = false
    else this.is_access_level_unlisted = true

    for (const [key, value] of Object.entries(this.spacePidNameDictionnary)) {
      if (key != this.space.pid) this.spacePidNameOptions.push({ text: value, value: key })
    }

    // LoadingScreen
    console.log('this.space.settings.LoadingScreen')

    console.log(this.space.settings.LoadingScreen)
    if (this.space.settings.LoadingScreen == null)
      this.space.settings.LoadingScreen = { tips: [], on_loaded: 'start_experience' }
    if (this.space.settings.LoadingScreen.on_loaded) this.on_loaded = this.space.settings.LoadingScreen.on_loaded
    else this.on_loaded = 'start_experience'
    console.log(this.space.settings.LoadingScreen)
  },
  beforeDestroy() {},
  methods: {
    BackToMySpace() {
      this.$router.go(-1)
      // this.$router.push({
      //   path: `/space/${this.space.pid}`,
      // })
    },

    getAnchorName(anchor_pid) {
      return this.$store.state.hoverlay.anchors.filter(anchor => anchor.pid == anchor_pid)[0].name
    },

    editPlacementAnchor(placementPid) {
      this.$router.push({
        path: `/edit-placement-anchor/${placementPid}`,
      })
    },
    editHobject(hobject_pid) {
      this.$router.push({
        path: `/edit-hobject/${hobject_pid}`,
      })
    },
    onQrCodeCopy() {
      this.$vs.notify({
        title: 'Success',
        text: this.$t('QrCodeCopy'),
        color: 'success',
        iconPack: 'feather',
        position: 'bottom-left',
        icon: 'icon-check-circle',
      })
    },

    create() {
      this.$router.push({
        path: `/create/${this.latitude}/${this.longitude}`,
      })
    },
    updateSpace() {
      this.space.present_to = new Date(this.present_to).toISOString()
      this.space.present_from = new Date(this.present_from).toISOString()
      // Clear thumbnail field if it's not a File
      if (this.space.thumbnail && this.space.thumbnail instanceof Blob === false) delete this.space.thumbnail
      this.$store
        .dispatch('hoverlay/updateSpace', this.space)
        .then(() => {
          this.$vs.loading.close()
          this.$vs.notify({
            text: 'Space updated',
            color: 'success',
            icon: 'check_box',
          })
          console.log('SUCCESS')
        })
        .catch(error => {
          console.log(error.message)
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'Error',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    reset() {
      this.initData()
      this.initMap()
      this.showOtherSpaces()
      this.initMarker()
    },
    initData() {
      var space_pid = this.$route.params.space_pid
      // Create a clone of the space
      this.space = Object.assign(
        {},
        this.spaces.find(space => space.pid == space_pid)
      )
      this.present_from = new Date(this.space.present_from)
      this.present_to = new Date(this.space.present_to)
    },
    getHobject(hobject_pid) {
      return JSON.parse(
        JSON.stringify(this.$store.state.hoverlay.hobjects.filter(hobject => hobject.pid == hobject_pid)[0])
      )
    },
  },
}
</script>

<style lang="scss"></style>
